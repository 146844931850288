<template>
  <div id="search">
    <div class="search flex-container">
      <!-- @image/order/order_serach.png -->
      <!-- <img src="" class='search_icon' @click='goSearch()'> -->
      <div style="width:30px;"></div>
      <!-- <div class="solid"></div> -->
      <input
        class="flex1"
        placeholder="搜索十九匠商城订单"
        type="text"
        v-model="searchVal"
      />
      <!-- <img src="@image/order/order_error.png" class='error' @click='removeSearchVal'> -->
      <div class="solid"></div>
      <img
        src="@image/order/order_serach.png"
        class="search_icon"
        @click="goSearch()"
        style="margin-right:30px;"
      />
    </div>
    <div class="">
      <div
        class="search_title flex-container"
        v-if="storageList.length != 0 && goodsName.length == 0"
      >
        <p class="flex1">历史搜索</p>
        <b @click="removeStorage">清除</b>
      </div>
      <div
        class="search_result_seesion flex-container"
        v-if="storageList.length != 0 && goodsName.length == 0"
      >
        <p v-for="item in storageList" :key="item.key" @click="goSearch(item)">
          {{ item }}
        </p>
      </div>
    </div>

    <order-reault
      :topTap="topTap"
      :goodsName="goodsName"
      v-if="goodsName"
    ></order-reault>
  </div>
</template>
<script>
import orderList from "../list/index.vue";
import orderReault from "../index.vue";
import noData from "@/components/noData/";
import { Toast } from "vant";

export default {
  name: "",
  data: () => ({
    topTap: false,
    searchVal: "",
    goodsName: "",
    storageList: [],
  }),
  created() {
    this.getStorage();
  },
  methods: {
    removeSearchVal() {
      this.searchVal = "";
      this.goodsName = "";
    },
    goSearch(mode = "default___") {
      // 去搜索
      if (mode != "default___") {
        this.searchVal = mode;
        this.goodsName = mode;
      } else {
        if (this.searchVal.length == 0) {
          Toast("十九匠商城通知:请输入搜索内容");
          return false;
        }
        this.goodsName = this.searchVal;
        this.setStorage(this.searchVal);
      }
    },
    getStorage() {
      let data = localStorage["shop_search_list"];
      if (data == undefined) {
        return false;
      } else {
        this.storageList = data.split(",");
      }
    },
    setStorage(val) {
      this.storageList.push(val);
      localStorage["shop_search_list"] = this.storageList;
    },
    removeStorage() {
      localStorage.removeItem("shop_search_list");
      this.storageList = [];
      Toast("十九匠商城通知:清除成功");
    },
  },
  components: {
    orderList,
    noData,
    orderReault,
  },
  beforeRouteLeave(to, from, next) {
    this.goodsName = "";
    next();
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

#search {
  height: 100%;
  background: #ebebeb;
  padding-top: rem(24);
}
.search {
  border-radius: rem(10);
  width: rem(710);
  margin: 0 auto rem(26);
  height: rem(40);
  align-items: center;
  background: #fff;
  padding: rem(30) 0;

  .search_icon {
    width: rem(36);
    height: rem(40);
    margin: 0 rem(20) 0 rem(32);
  }
  .solid {
    width: rem(2);
    height: rem(32);
    background: #ebebeb;
    margin-right: rem(24);
  }
  input {
    font-size: rem(30);
    color: #808080;
    border: none;
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #b3b3b3;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #b3b3b3;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #b3b3b3;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b3b3b3;
  }
  .error {
    width: rem(36);
    height: rem(36);
    background: #ebebeb;
    display: block;
    margin: 0 rem(32);
    border-radius: 50%;
  }
}

.search_title {
  font-size: rem(26);
  padding: rem(12) rem(32);
  p {
    color: #b3b3b3;
  }
  b {
    color: #343434;
  }
}

.search_result_seesion {
  margin: 0 rem(15);
  flex-wrap: wrap;
  p {
    width: rem(230);
    height: rem(64);
    background: #e6e6e6;
    border-radius: rem(4);
    line-height: rem(64);
    text-align: center;
    font-size: rem(26);
    color: #808080;
    margin: rem(20) rem(5);
  }
}
</style>
